<script>
  import Chart from 'chart.js';
  import ChartDataLabels from 'chartjs-plugin-datalabels'; // https://chartjs-plugin-datalabels.netlify.app
  import { Line, mixins } from 'vue-chartjs';
  const { reactiveProp } = mixins;

  Chart.defaults.global.defaultFontColor = 'white';
  Chart.plugins.unregister(ChartDataLabels); // have to unregister if you only want to turn it on for selected charts

  export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
    mounted() {
      this.renderChart(this.chartData, this.options);
    }
  };
</script>
